:root {
    --primary1: #6a7782;
    --primary2: #ff9d1c;
    --secondary: #324854;
    --neutral: #eaeef2;
  }
  .App {
    text-align: center;
    display: flex;
  }
  body {
    background-color: #f0f2f5;
  }
  html,
  body,
  #root,
  .App {
    width: 100%;
    height: 100%;
  }
  .editevent .Mui-disabled {
    opacity: 1.38 !important;
  }
  .MuiDataGrid-cell {
    min-height: fit-content !important;
    max-height: fit-content !important;
    line-height: 20px !important;
    display: block !important;
    padding: 12px 6px 12px 6px !important;
  }
  /* .MuiDataGrid-cell:first-child{
    line-height: 50px!important;
  } */
  .MuiDataGrid-row {
    max-height: none !important;
  }
  textarea {
    overflow: auto !important;
    max-height: 63px;
  }
  @media only screen and (max-width: 980px) {
    .App {
      display: unset;
    }
    .App .css-i9gxme {
      position: fixed;
      top: 0;
      z-index: 10;
      width: 100%;
    }
    .App #alertsuccess {
      position: fixed;
      top: 0;
      z-index: 10;
      width: 100%;
    }
    .App .css-1m04nb5 {
      margin-left: auto;
    }
    .div-app {
      height: 100vh !important;
    }
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #262626;
    /* min-height: 100vh; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: #ffffff;
    position: fixed;
    width: 100%;
  }
  .text-error {
    text-align: left;
    color: #c20202 !important;
    font-size: 12px;
  }
  /* .css-17z57jz-MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
  .css-17z57jz-MuiDataGrid-root .MuiDataGrid-cell:focus {
    outline: none !important;
  }
  
  .css-17z57jz-MuiDataGrid-root .MuiDataGrid-cell {
    
    border-bottom: none !important; 
  } */
  /* .css-99lfi7-MuiDataGrid-columnsContainer{
    display: none !important;
  } */
  /* .css-17z57jz-MuiDataGrid-root{
    border: none !important;
  }
  } */
  .css-ece9u5 {
    width: 100% !important;
  }
  .App-link {
    color: #61dafb;
  }
  #button-secondary {
    height: 40px;
    border-color: var(--secondary) !important;
    color: var(--secondary) !important;
  }
  #button-primary {
    height: 40px;
    width: 100%;
    background: var(--secondary) !important;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .monumentForm {
    box-shadow: 0 0 0 0 rgb(0 0 0 / 5%), 0px 4px 4px 0 rgb(0 0 0 / 12%),
      0 8px 19px -1px rgb(0 0 0 / 20%);
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
    background: white;
    border-radius: 8px;
  }
  
  @media only screen and (min-width: 980px) {
    /* .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
      position: initial !important;
    }
    .css-1u2w381-MuiModal-root-MuiDrawer-root {
      position: inherit!important;
      z-index: 0!important;
      right: 0;
      bottom: 0;
      top: 0;
      left: 0;
    } */
    /* .css-4t3x6l-MuiPaper-root-MuiDrawer-paper {
      background-color: #fff;
      color: rgba(0, 0, 0, 0.87);
      -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      box-shadow: none !important;
      overflow-y: auto;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
     
    
     
      z-index: 0!important;
      -webkit-overflow-scrolling: touch;
      position: fixed;
      top: 64px!important;
      outline: 0;
      left: 0;
  } */
  }
  .row {
    display: inline-flex;
  }
  .column {
    text-align: left;
  }
  .column #mail {
    font-size: 10pt;
    color: #f5f5f5;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-weight: 100;
  }
  #username {
    margin-bottom: -10px;
    color: #cfcfcf;
    font-size: 16px;
    font-size: calc(1px + 2vmin);
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-weight: bold;
  }
  @media only screen and (max-width: 980px) {
    #username {
      font-size: 20px;
    }
  }
  .username .row {
    width: 100%;
  }
  .username .row .logoImg {
    width: 48px;
    height: 60px;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 2px;
  }
  .username .row .logoImg img {
    width: 48px;
    height: 48px;
  }
  .column.column-left {
    margin-right: 4px;
  }

  .multiple-input {
    min-height: 40px;
  }

  .multiple-input .MuiInput-root {
    height: 100%;
    margin-top: 3px;
  }

  @media only screen and (min-width: 980px) {
    .LoginPortail {
      position: absolute;
      width: 30%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .monumentForm {
      position: relative;
      overflow-y: auto;
      height: 80vh;
      padding: 32px 24px;
      width: 600px;
      display: flex;
      justify-content: center;
      z-index: -1;
      margin-top: 50px;
    }

    .equipementForm {
      height: unset;
      max-height: 80vh;
    }

    .reponsive-container {
      display: flex;
      justify-content: space-between;
      gap: 16px;
    }
    
    .reponsive-container .MuiFormControl-root {
      flex: 1;
    }
    
    @media (max-width: 600px) {
      .reponsive-container {
        flex-direction: column;
      }
    }

    .monumentForm h3 {
      text-align: left;
      margin-bottom: 16px;
    }
    .formLogin {
      border: 1px solid #80808047;
      box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14),
        0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
      /* width: 30%; */
      border-radius: 8px;
      padding: 56px;
      background: white;
    }
    .forgetpassword {
      margin-top: 58px !important;
    }
    .navBarDesktop {
      position: fixed;
      height: 100%;
      min-width: 264px;
      width: 20%;
      max-width: 300px;
      background: #262626;
    }
    .css-1p823my-MuiListItem-root {
      padding: 8px !important;
      margin-left: 8px;
    }
    .menu:hover {
      background: #3c3c3c;
      border-radius: 8px;
      width: 95%;
      margin-left: 5px !important;
    }
    .css-i4bv87-MuiSvgIcon-root {
      fill: #a5a5a5;
    }
    /* .css-1p823my-MuiListItem-root:active{
      background: #4d5d6c;
    } */
    .menu.active {
      background: #515151;
      border-radius: 8px;
  
      width: 95% !important;
      margin-left: 5px !important;
    }
    .menu {
      margin-bottom: 4px;
      margin-top: 4px;
    }
    .active {
      background: #515151;
      border-radius: 8px;
      margin-bottom: 4px;
      margin-top: 4px;
      width: 95% !important;
      margin-left: 5px !important;
    }
    .separator {
      border-top: 1px solid #606770;
      width: 100%;
    }
    .css-h4y409-MuiList-root {
      padding-top: 0 !important;
    }
    .ml-264 {
      margin-left: 264px;
    }
  }
  
  #alertsuccess {
    height: 460px;
  }
  .separator {
    border-top: 1px solid #606770;
    margin: 16px 0 16px 0px;
  }
  
  .MuiDataGrid-row:nth-child(odd) {
    background-color: #3f617e26;
  }
  .MuiDataGrid-row:hover {
    background-color: #3f617e85 !important;
    cursor: pointer;
  }
  
  .css-1c2i806-MuiFormLabel-root-MuiInputLabel-root {
    font-size: 1.3rem !important;
    line-height: 1em !important;
  }
  
  .forgetpassword {
    margin-top: 180px;
  }
  .css-96uuyl {
    background-color: #fff;
    border: 1px solid #c6c6c6;
    width: auto;
    height: 32px;
    font-size: 16px;
    padding: 4px;
    -webkit-transition: border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
      "Segoe UI Symbol";
  }
  .css-96uuyl:focus {
    border: 0.5px solid var(--secondary) !important;
    outline: 0.1rem solid var(--secondary) !important;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 /25%);
  }
  .css-1iz4iln {
    /* padding: 0px 3px; */
    height: 67%;
  }
  
  .eventsGRCForm {
    width: 60vw !important;
    min-height: 30vw;
  }
  .menu {
    color: white;
  }
  .searchbox {
    width: -webkit-fill-available;
  }
  
  @media only screen and (max-width: 980px) {
    .eventsGRCForm {
      width: 96% !important;
      padding-left: 0 !important;
      display: flex;
      align-items: center;
      margin-left: 2%;
    }
  }
  
  .inputForm {
    display: flex;
    padding: 0 !important;
    padding-left: 6px !important;
    margin-top: 10px;
    margin-bottom: 10px;
    height: 38px !important;
    border-radius: 4px;
    position: relative;
    background-color: #fff;
    border: 1px solid #c6c6c6;
    font-size: 16px;
    /* padding: 8px; */
    -webkit-transition: border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
      "Segoe UI Symbol";
  }

  .multiple-select {
    height: unset !important;
  }
  .inputForm.trello {
    padding-left: 8px !important;
    width: 98.6% !important;
  }
  /* .MuiAutocomplete-hasPopupIcon.css-1kpdewa-MuiAutocomplete-root .MuiAutocomplete-inputRoot, 
  .MuiAutocomplete-hasClearIcon.css-1kpdewa-MuiAutocomplete-root .MuiAutocomplete-inputRoot{
    padding: 0!important;
  } */
  .inputForm:focus {
    border: 0.5px solid var(--secondary) !important;
    outline: 0.1rem solid var(--secondary) !important;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 /25%);
  }
  .MuiNativeSelect-root {
    width: 100%;
  }
  .textareaForm:focus {
    border: 0.5px solid var(--secondary) !important;
    outline: 0.1rem solid var(--secondary) !important;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 /25%);
  }
  .textareaForm {
    width: 97%;
    border-radius: 4px;
    position: relative;
    background-color: #fff;
    border: 1px solid #c6c6c6;
    font-size: 16px;
    padding: 8px;
    -webkit-transition: border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
      "Segoe UI Symbol";
  }
  .facultatif {
    color: rgba(0, 0, 0, 0.3);
  }
  #connect {
    margin-bottom: -15px;
    margin-top: 23px;
    height: 36px;
    background-color: var(--secondary);
    color: #ffffff;
  
    /* color:#1976d2; */
  }
  
  .eventDuration {
    display: flex;
    align-items: center;
  }
  .eventDuration input {
    margin-right: 2px;
    margin-left: 2px;
  }
  #labelForm {
    color: rgba(0, 0, 0, 0.6);
  }
  
  #labelForm2 {
    color: rgba(0, 0, 0, 0.6);
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    padding: 0;
    position: relative;
    display: block;
    transform-origin: top left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 133%;
    position: absolute !important;
    left: 0 !important;
    top: 0 !important;
    -webkit-transform: translate(0, -1.5px) scale(0.75);
    -moz-transform: translate(0, -1.5px) scale(0.75);
    -ms-transform: translate(0, -1.5px) scale(0.75);
    transform: translate(0, -1.5px) scale(0.75);
    -webkit-transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
      -webkit-transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
      max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
      transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
      max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  }
  .css-hip9hq-MuiPaper-root-MuiAppBar-root {
    background-color: #262626 !important;
    border-color: #262626 !important;
  }
  
  label + .css-hrjh4n-MuiInputBase-root {
    margin-top: 24px;
  }
  .Mui-focused {
    color: rgba(0, 0, 0, 0.6) !important;
  }
  .MuiOutlinedInput-root.Mui-focused {
    border: 0.4px solid var(--secondary) !important;
    outline: 0.1rem solid var(--secondary) !important;
  }
  .css-10hburv-MuiTypography-root {
    color: #ffffff;
  }
  .css-1iz4iln {
    /*padding: 6px 8px!important;*/
    height: 50% !important;
  }
  
  @media (min-width: 900px) {
    .css-1kcggdq-MuiInputBase-root .MuiInputBase-input {
      width: 26ch !important;
      padding: 0 !important;
      /* padding-left: calc(1em + 32px)!important; */
    }
  }
  .css-yz9k0d-MuiInputBase-input {
    padding: unset !important;
    display: unset !important;
    height: 32px !important;
  }
  .css-1ftfn2c-MuiInputBase-root {
    width: 100% !important;
    /* margin-left: 30px !important; */
  }
  .css-zx3x9t {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  /* .MuiFormControl-root{
    display: block !important;
     
  } */
  
  .css-14ps8yx-MuiDataGrid-virtualScrollerContent {
    width: 100% !important;
    min-height: 416px !important;
  }
  /* .css-17z57jz-MuiDataGrid-root .MuiDataGrid-row {
    max-height: 64px!important;
    min-height: 64px!important;
  } */
  .div-app {
    z-index: 1;
    background: rgb(240, 242, 245);
  }
  @media only screen and (max-width: 980px) {
    .div-app {
      margin-top: 60px;
    }
    .div-app h3 {
      margin-bottom: 10px;
    }
  }
  .css-4t3x6l-MuiPaper-root-MuiDrawer-paper {
    background-color: #262626 !important;
  }
  .css-1kpdewa-MuiAutocomplete-root
    .MuiOutlinedInput-root
    .MuiAutocomplete-input {
    padding: 8px !important;
    height: 24px !important;
  }
  .app-main {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url("https://i.ibb.co/0cGstYT/background-transp-gpg.png");
    background-size: cover;
  }
  .durationHourLabel {
    color: rgba(0, 0, 0, 0.6);
  }
  
  .durationMinLabel {
    color: rgba(0, 0, 0, 0.6);
  }
  
  textarea {
    resize: vertical !important;
  }
  
  h3 {
    line-height: 40px;
    display: block;
    text-align: left;
    /* text-transform: uppercase; */
    font-weight: 400;
    font-family: "Roboto";
    color: #212121;
    letter-spacing: 0;
    margin-top: 0;
    font-size: 24px;
    margin-bottom: 0;
  }
  
  .attachedFilesContainer {
    width: 100%;
    margin-top: 5px;
  }
  
  .attachedFilesContainer .attachedFile {
    background-color: #ebebeb;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    margin-right: 5px;
    width: 175px;
    height: 40px;
    margin-bottom: 5px;
    float: left;
  }
  
  .attachedFilesContainer .attachedFile .attachedFileName {
    padding-left: 10px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 10pt;
    color: #1e1e1e;
  }
  .attachedFilesContainer .attachedFile .attachedFileIcon {
    margin-left: auto;
    color: #aeaeae;
    padding-right: 10px;
    padding-left: 20px;
  }
  #alertsuccess {
    width: 400px;
  }
  .attachedFilesContainer .attachedFile .attachedFileIcon:hover {
    color: rgba(0, 0, 0, 0.4);
    cursor: pointer;
    min-height: 100%;
  }
  .css-17z57jz-MuiDataGrid-root
    .MuiDataGrid-cell--textLeft.MuiDataGrid-cell--withRenderer {
    width: 232px !important;
    /* max-width: 232px !important;
    min-width: 232px !important; */
  }
  
  .iconsearch {
    position: relative !important;
  }
  
  @media only screen and (max-width: 980px) {
    #button-primary {
      background-color: var(--secondary);
    }
  }
  
  .editDuration {
    display: flex;
    align-items: center;
  }
  .editDuration input {
    margin-top: 17px;
  }
  .editDuration span {
    margin-top: 12px;
    margin-left: 5px;
    margin-right: 5px;
  }
  
  .MuiOutlinedInput-root {
    padding: 0 !important;
  }
  .eventDura .MuiOutlinedInput-root {
    padding-right: 12px !important;
  }
  .MuiOutlinedInput-root input {
    padding: 8px !important;
    height: 24px !important;
  }
  .formControl {
    line-height: 25px;
    padding-bottom: 14px;
    display: -webkit-inline-box !important;
    float: left;
  }
  .search {
    width: 30%;
    margin-top: 0px;
    height: 38px !important;
    color: #a5a5a5 !important;
  }
  .selectBox {
    width: 30%;
    margin-right: 16px;
  }
  .selectBox2 {
    width: 35%;
    margin-right: 16px;
  }
  .inputForm.select .MuiInput-root::after {
    border: none !important;
  }
  .inputForm.select .MuiInput-root::before {
    border: none !important;
  }
  .inputForm.select,
  .inputForm.title {
    margin-top: 0px !important;
    padding: 0 8px !important;
  }
  .inputForm.familyref {
    padding: 0 8px !important;
  }
  
  @media only screen and (max-width: 980px) {
    /* For mobile phones: */
    .app-main {
      background-image: url("bg.png") !important;
    }
    .div-app{
      margin-top: 200px !important;
    }
    header {
      background-color: #262626 !important;
    }
    .navbar .MuiPaper-root {
      background-color: #262626;
      width: 75%;
    }
    .LoginPortail {
      padding: 232px 32px;
    }
    .div-app {
      padding-left: 0 !important;
      padding-right: 0 !important;
      width: 100% !important;
    }
  
    .monumentForm {
      /* padding-top: 100px !important; */
      box-shadow: unset !important;
      background-color: unset !important;
      border-radius: unset !important;
      height: 100vh !important;
    }
    .monumentForm form {
      height: 100vh !important;
    }
  
    .css-4t3x6l-MuiPaper-root-MuiDrawer-paper {
      text-align: center;
    }
    .formLogin {
      padding: 32px 18px;
      border: 1px solid #80808047;
      background-color: #ffffff;
      box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14),
        0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
      border-radius: 8px;
    }
    .monumentForm {
      /* padding: 56px 32px; */
      z-index: -1;
      position: relative;
      border-radius: 0px;
      box-shadow: none;
    }
  
    .forgetpassword {
      margin-top: 220px;
    }
    .app-main {
      background-color: #f0f2f5;
    }
    #alertsuccess {
      left: 0px;
      text-align: -webkit-center;
      position: fixed;
      top: 0;
      z-index: 10;
    }
    .selectBox {
      width: 100%;
      margin-bottom: 16px;
    }
    .selectBox2 {
      width: 100%;
      margin-bottom: 16px;
    }
    .search {
      width: 100%;
    }
    .formControl {
      display: flex !important;
    }
    .menu.active {
      background: #515151;
      border-radius: 8px;
    }
    .menu {
      margin-bottom: 2px;
      margin-top: 2px;
    }
    .eventDura .MuiOutlinedInput-root {
      margin-right: 16px;
    }
  
    /* .css-ece9u5 {
      width: 100% !important;
      width: 338px !important;
      padding-top: 140px;
    } */
    .div-app {
      background: #ffffff !important;
      /* padding-top: 88px; */
    }
    .alert_success {
      background: #ffffff !important;
      width: 100%;
    }
  
    .selectBox,
    .selectBox2,
    .inputForm.search {
      margin-bottom: 8px !important;
    }
    #labelForm.search {
      display: none !important;
    }
    .backicon {
      display: none !important;
    }
  
    .textareaForm {
      width: 95%;
    }
  
    .btn-hover {
      background-color: #f8f9fa !important;
    }
  }
  
  /* #field_error
    .css-1hbvpl3-MuiSvgIcon-root{
    color:#f44336;
  } */
  #field_error #labelForm2 {
    color: #f44336 !important;
  }
  
  .d-flex{
    display: flex;
  }
  .justify-content-between {
    justify-content: space-between;
  }
  
  .userSelect input
  {
  color: #fff;
  }
  
  .userSelect svg {
    fill: #cfcfcf;
  }
  
  .btn-hover:hover{
    background-color: #f8f9fa !important;
  }
  .table-height{
    height: 424px
  }
  @media only screen and (min-height: 841px) {
    .table-height{
      height: 526px
    }
  }
  .align-items-center{
    align-items: center;
  }